var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-bar" },
        [
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" } },
            [
              _c("router-link", { attrs: { to: "" } }, [
                _c("span", [_vm._v(_vm._s(_vm.userInfo.username))])
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/mypage" } },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { icon: "el-icon-user" } },
                        [_vm._v(_vm._s(_vm.$t("mypage")))]
                      )
                    ],
                    1
                  ),
                  _c("el-dropdown-item", { attrs: { icon: "el-icon-coin" } }, [
                    _vm._v(_vm._s(_vm.$t("payment")))
                  ]),
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdmin,
                          expression: "isAdmin"
                        }
                      ],
                      attrs: { to: "/admin/users" }
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { icon: "el-icon-user" } },
                        [_vm._v("관리자")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/alarm" } },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { icon: "el-icon-bell" } },
                        [_vm._v(_vm._s(_vm.$t("noti")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a",
                    { on: { click: _vm.handleLog } },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { icon: "el-icon-unlock" } },
                        [_vm._v(_vm._s(_vm.$t("logout")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a",
            { staticClass: "popBtn" },
            [
              _c(
                "el-button",
                {
                  staticClass: "top_btn",
                  attrs: { slot: "reference" },
                  on: { click: _vm.handlePop },
                  slot: "reference"
                },
                [
                  _c("span", { staticClass: "material-icons" }, [
                    _vm._v(" settings ")
                  ])
                ]
              )
            ],
            1
          ),
          _c("router-link", { attrs: { to: "/" } }, [
            _c("button", { staticClass: "top_btn" }, [
              _c("span", { staticClass: "material-icons" }, [_vm._v(" home ")])
            ])
          ]),
          _c(
            "button",
            {
              staticClass: "top_btn",
              on: {
                click: function($event) {
                  return _vm.langChange()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.currentLang === "ko" ? "EN" : "KR") + " ")]
          )
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.visible
          ? _c(
              "div",
              { staticClass: "dim on" },
              [_c("Product-Form", { on: { openModal: _vm.openModal } })],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }