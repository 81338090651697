import { mapState } from "vuex";

export default {
  data() {
    return {
      langList: [
        {
          text: "한국어",
          value: "ko",
        },
        {
          text: "English",
          value: "en",
        },
      ],
    };
  },

  computed: {
    ...mapState(["currentLang"]),
  },
  methods: {
    langChange() {
      let lang = this.currentLang === "en" ? "ko" : "en";
      this.$i18n.locale = lang;
      this.$store.commit("langChange", lang);
      document.querySelector("html").setAttribute("lang", lang);
    },
  },
};
