var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.navVisible ? _c("Navbar") : _vm._e(),
      _c("transition", [_c("router-view")], 1),
      _vm.footerVisible ? _c("Footer") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }