var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "productForm modal" }, [
    _c("div", { staticClass: "modal_cover" }),
    _c("div", { staticClass: "modal_content" }, [
      _c("div", { staticClass: "m_header" }, [
        _c("div", [_vm._v(_vm._s(_vm.$t("register_goods")))]),
        _c(
          "button",
          {
            staticClass: "close",
            on: {
              click: function($event) {
                return _vm.closeModal()
              }
            }
          },
          [_c("span", { staticClass: "material-icons" }, [_vm._v("close")])]
        )
      ]),
      _c("div", { staticClass: "m_body" }, [
        _c("div", { staticClass: "buttonWrap" }, [
          _c(
            "button",
            {
              class: { active: _vm.ownVisible },
              on: {
                click: function($event) {
                  return _vm.productTab("own")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("own_goods")) + " ")]
          ),
          _c(
            "button",
            {
              class: { active: !_vm.ownVisible },
              on: {
                click: function($event) {
                  return _vm.productTab("competitor")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("competitor_goods")) + " ")]
          )
        ]),
        _c("div", { staticClass: "product-list" }, [
          _vm.ownVisible
            ? _c(
                "div",
                { staticClass: "productAdd" },
                [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.type,
                          expression: "type"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        name: "type",
                        id: "amazon",
                        value: "amazon",
                        checked: ""
                      },
                      domProps: { checked: _vm._q(_vm.type, "amazon") },
                      on: {
                        change: function($event) {
                          _vm.type = "amazon"
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("amazon")) + " ")
                  ]),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.type,
                          expression: "type"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        name: "type",
                        id: "domestic",
                        value: "naver"
                      },
                      domProps: { checked: _vm._q(_vm.type, "naver") },
                      on: {
                        change: function($event) {
                          _vm.type = "naver"
                        }
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("domestic")) + " ")
                  ]),
                  _c("ul", [
                    _c("li", [
                      _vm.type == "amazon"
                        ? _c("div", [
                            _c("h2", [_vm._v("ASIN")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.asin,
                                  expression: "asin"
                                }
                              ],
                              attrs: {
                                type: "text",
                                alt: "product name",
                                placeholder: "ASIN"
                              },
                              domProps: { value: _vm.asin },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.asin = $event.target.value
                                }
                              }
                            })
                          ])
                        : _c("div", [
                            _c("h2", [_vm._v("URL")]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.url,
                                  expression: "url"
                                }
                              ],
                              attrs: {
                                type: "text",
                                alt: "product name",
                                placeholder: "www.example.com"
                              },
                              domProps: { value: _vm.url },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.url = $event.target.value
                                }
                              }
                            })
                          ]),
                      _c("h2", [_vm._v(_vm._s(_vm.$t("name")))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.productName,
                            expression: "productName"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "제품명 (영어로만 표기해 주세요)"
                        },
                        domProps: { value: _vm.productName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.productName = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm.productId
                    ? _c(
                        "button",
                        {
                          staticClass: "btn delete",
                          on: {
                            click: function($event) {
                              return _vm.removeProduct(_vm.productId)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.submit()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("register_goods")))]
                      )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "productAdd competitor" },
                [
                  _c("div", [
                    _c(
                      "ul",
                      _vm._l(_vm.competitorList, function(data, i) {
                        return _c("li", { key: i, staticClass: "flexB" }, [
                          _c("div", [
                            data.type == "amazon"
                              ? _c("p", { staticClass: "flexL" }, [
                                  _c("span", [_vm._v("ASIN")]),
                                  _vm._v(" " + _vm._s(data.asin) + " ")
                                ])
                              : _c("p", { staticClass: "flexL" }, [
                                  _c("span", [_vm._v("URL")]),
                                  _vm._v(" " + _vm._s(data.url) + " ")
                                ]),
                            _c("div", { staticClass: "flexL" }, [
                              _c("p", [
                                _c("span", [_vm._v("PRODUCT")]),
                                _vm._v(" " + _vm._s(data.productName) + " ")
                              ])
                            ])
                          ]),
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.removeCompetitorProduct(data._id)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "material-icons" }, [
                                _vm._v("delete_forever")
                              ])
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ]),
                  _c(
                    "ul",
                    { class: { comptAdd: _vm.competitorList.length > 0 } },
                    [
                      _c("li", [
                        _vm.type == "amazon"
                          ? _c("div", [
                              _c("h2", [_vm._v("ASIN")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.competitorAsin,
                                    expression: "competitorAsin"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  alt: "product name",
                                  placeholder: "ASIN"
                                },
                                domProps: { value: _vm.competitorAsin },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.competitorAsin = $event.target.value
                                  }
                                }
                              })
                            ])
                          : _c("div", [
                              _c("h2", [_vm._v("URL")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.competitorUrl,
                                    expression: "competitorUrl"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  alt: "product name",
                                  placeholder: "www.example.com"
                                },
                                domProps: { value: _vm.competitorUrl },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.competitorUrl = $event.target.value
                                  }
                                }
                              })
                            ]),
                        _c("h2", [_vm._v(_vm._s(_vm.$t("name")))]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.competitorProductName,
                              expression: "competitorProductName"
                            }
                          ],
                          attrs: { type: "text", placeholder: "제품명" },
                          domProps: { value: _vm.competitorProductName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.competitorProductName = $event.target.value
                            }
                          }
                        })
                      ])
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: {
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.submit("competitor")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("register_comp_goods")))]
                  )
                ],
                1
              )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }