var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "floatAlert" }, [
    _c("p", [_vm._v(_vm._s(_vm.alert.alertText))]),
    _c(
      "a",
      { staticClass: "btnCancel", attrs: { href: "javascript:void(0);" } },
      [_vm._v("닫기")]
    ),
    _c(
      "a",
      {
        staticClass: "material-icons btnClose",
        attrs: { href: "javascript:void(0);" },
        on: { click: _vm.handleClose }
      },
      [_vm._v("close")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }