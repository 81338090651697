import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";
//import elementEnLocale from "../plugins/i18n/vuetify/en"; // element-ui lang
import koLocale from "./ko";
import enLocale from "./en";

Vue.use(VueI18n);

const messages = {
  ko: {
    ...koLocale,
  },
  en: {
    ...enLocale,
  },
};

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: Cookies.get("language") || "en",
  messages,
});

export default i18n;
