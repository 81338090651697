var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _c("div", { staticClass: "ftWrap" }, [
      _c("div", { staticClass: "ft-left" }, [
        _c("ul", [
          _c("li", [
            _c("a", { attrs: { href: "/terms" } }, [
              _vm._v(_vm._s(_vm.$t("userTerm")) + " "),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("privacyPolicy")))
            ])
          ]),
          _c("li", [
            _vm._v(" " + _vm._s(_vm.$t("inquiry")) + " "),
            _c("ul", [_c("li", [_vm._v(_vm._s(_vm.$t("ER_inquiry")))])])
          ]),
          _c("li", [
            _vm._v(" " + _vm._s(_vm.$t("meet")) + " "),
            _c("ul", [
              _c("li", [
                _c("a", { attrs: { href: "", target: "_blank" } }, [
                  _vm._v(_vm._s(_vm.$t("facebook")))
                ])
              ]),
              _c("li", [
                _c("a", { attrs: { href: "", target: "_blank" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("instagram")))
                ])
              ])
            ])
          ])
        ]),
        _vm._m(0)
      ]),
      _c("div", { staticClass: "ft-right" }, [
        _c("ul", [
          _c("li", [_vm._v(_vm._s(_vm.$t("aboutCompany")))]),
          _c("li", [_c("address", [_vm._v(_vm._s(_vm.$t("companyAddress")))])]),
          _c("li", [_vm._v(_vm._s(_vm.$t("businessNumber")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("companyEmail")))]),
          _c("li", [_vm._v(_vm._s(_vm.$t("companyTel")))]),
          _c("li", [_vm._v(" " + _vm._s(_vm.$t("copyright")) + " ")])
        ]),
        _c("img", {
          attrs: {
            src: require("../assets/images/reviewmind/logo2.png"),
            alt: ""
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("리뷰마인드를 만나보세요")]),
      _c("li", [
        _c("a", { attrs: { href: "", target: "_blank" } }, [
          _c("img", { attrs: { src: require("../assets/images/sns_fb.png") } })
        ]),
        _c("a", { attrs: { href: "", target: "_blank" } }, [
          _c("img", {
            attrs: { src: require("../assets/images/sns_insta.png") }
          })
        ]),
        _c("a", [
          _c("img", { attrs: { src: require("../assets/images/sns_kk.png") } })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }