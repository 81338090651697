export const baseUrl = "https://api.dev.reviewmind.com";
// export const baseUrl = "http://114.110.129.239:3001";
// export const baseUrl = "http://54.180.99.59:3000";
export const header = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  "X-Content-Type-Options": "nosniff",
  "X-XSS-Protection": "1",
  "Access-Control-Allow-Credentials": true,
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
};
export const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Content-Type-Options": "nosniff",
  authorization: localStorage.getItem("accesstoken"),
};

export const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("accesstoken"),
  },
};
