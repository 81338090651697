var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "pageHeader", attrs: { id: "pageHeader" } },
    [
      _c("div", { staticClass: "burger", on: { click: _vm.openMenu } }, [
        _c("span"),
        _c("span"),
        _c("span")
      ]),
      _c("nav", { attrs: { id: "nav" } }, [
        _c("a", { staticClass: "nav_top" }, [
          _c(
            "span",
            { staticClass: "nav_close", on: { click: _vm.closeMenu } },
            [_c("span", { staticClass: "material-icons" }, [_vm._v("close")])]
          )
        ]),
        _c(
          "div",
          { staticClass: "navBar" },
          [
            _c("ul", { staticClass: "mainMenu" }, [
              _c("li", { on: { click: _vm.closeMenu } }, [
                _vm._m(0),
                _vm._m(1)
              ]),
              _c(
                "li",
                { on: { click: _vm.closeMenu } },
                [
                  _c(
                    "router-link",
                    { class: { active: _vm.gnbMenu == 1 }, attrs: { to: "/" } },
                    [_vm._v(" " + _vm._s(_vm.$t("product")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "li",
                { on: { click: _vm.closeMenu } },
                [
                  _c(
                    "router-link",
                    {
                      class: { active: _vm.gnbMenu == 2 },
                      attrs: { to: "/company", alt: "회사소개" }
                    },
                    [_vm._v(_vm._s(_vm.$t("company")))]
                  )
                ],
                1
              ),
              _c(
                "li",
                { on: { click: _vm.closeMenu } },
                [
                  _c(
                    "router-link",
                    {
                      class: { active: _vm.gnbMenu == 3 },
                      attrs: { to: "/develop", alt: "technology" }
                    },
                    [_vm._v(_vm._s(_vm.$t("technology")))]
                  )
                ],
                1
              ),
              _c(
                "li",
                { on: { click: _vm.closeMenu } },
                [
                  _c(
                    "router-link",
                    {
                      class: { active: _vm.gnbMenu == 4 },
                      attrs: { to: "/culture", alt: "culture" }
                    },
                    [_vm._v("Culture")]
                  )
                ],
                1
              ),
              _c(
                "li",
                { on: { click: _vm.closeMenu } },
                [
                  _c(
                    "router-link",
                    {
                      class: { active: _vm.gnbMenu == 6 },
                      attrs: { to: "/pricing", alt: "pricing" }
                    },
                    [_vm._v("Pricing")]
                  )
                ],
                1
              ),
              _c(
                "li",
                { on: { click: _vm.closeMenu } },
                [
                  _c(
                    "router-link",
                    {
                      class: { active: _vm.gnbMenu == 5 },
                      attrs: { to: "/contact", alt: "contact" }
                    },
                    [_vm._v("Contact")]
                  )
                ],
                1
              ),
              (_vm.isLogin && _vm.isPaid) || _vm.isAdmin
                ? _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: "/dashboard/reviewmind",
                            alt: "dashboard"
                          }
                        },
                        [_vm._v("Dashboard")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("ul", { staticClass: "dashMainmenu" }, [
              _vm._m(2),
              _c(
                "li",
                { class: { active: _vm.dashboardMenu == 1 } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/reviewmind" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-1.png"),
                          alt: "아이콘"
                        }
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-11.png"),
                          alt: "아이콘"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("review_status")) + " ")
                    ]
                  )
                ],
                1
              ),
              _c(
                "li",
                { class: { active: _vm.dashboardMenu == 2 } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/reviewmind2" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-2.png"),
                          alt: "아이콘"
                        }
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-22.png"),
                          alt: "아이콘"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("review_insights")) + " ")
                    ]
                  )
                ],
                1
              ),
              _c(
                "li",
                { class: { active: _vm.dashboardMenu == 3 } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/reviewmind3" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-3.png"),
                          alt: "아이콘"
                        }
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-33.png"),
                          alt: "아이콘"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("product_insights")) + " ")
                    ]
                  )
                ],
                1
              ),
              _c(
                "li",
                { class: { active: _vm.dashboardMenu == 4 } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/reviewmind4" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-4.png"),
                          alt: "아이콘"
                        }
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-44.png"),
                          alt: "아이콘"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("competitive_insights")) + " ")
                    ]
                  )
                ],
                1
              ),
              _c(
                "li",
                { class: { active: _vm.dashboardMenu == 5 } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/reviewmind5" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-5.png"),
                          alt: "아이콘"
                        }
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-55.png"),
                          alt: "아이콘"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("customer_insights")) + " ")
                    ]
                  )
                ],
                1
              ),
              _c(
                "li",
                { class: { active: _vm.dashboardMenu == 6 } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/reviewmind6" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-6.png"),
                          alt: "아이콘"
                        }
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-66.png"),
                          alt: "아이콘"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("review_Marketing")) + " ")
                    ]
                  )
                ],
                1
              ),
              _c(
                "li",
                { class: { active: _vm.dashboardMenu == 7 } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/reviewmind7" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-7.png"),
                          alt: "아이콘"
                        }
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/reviewmind/nav-icon-77.png"),
                          alt: "아이콘"
                        }
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("reputation_management")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _c("TopBar"),
            _c("ul", { staticClass: "rightMenu" }, [
              _vm._m(3),
              _vm.isAdmin
                ? _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.move("users")
                          }
                        }
                      },
                      [_vm._v("관리자")]
                    )
                  ])
                : _vm._e(),
              _vm.isLogin
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { alt: "마이페이지" },
                        on: { click: _vm.handleProfile }
                      },
                      [_vm._v(_vm._s(_vm.$t("mypage")))]
                    )
                  ])
                : _vm._e(),
              _c("li", { staticClass: "menuAlarm" }, [
                _c(
                  "a",
                  {
                    attrs: { alt: "알림" },
                    on: {
                      click: function($event) {
                        return _vm.move("alarm")
                      }
                    }
                  },
                  [_vm._v("Alarm")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.newMsg > 0,
                        expression: "newMsg > 0"
                      }
                    ],
                    staticClass: "newMsg"
                  },
                  [_vm._v(_vm._s(_vm.newMsg))]
                )
              ]),
              _c("li", { staticClass: "menuAlarm" }, [
                _c(
                  "a",
                  {
                    attrs: { alt: "문의하기" },
                    on: {
                      click: function($event) {
                        return _vm.move("qna")
                      }
                    }
                  },
                  [_vm._v("Q&A")]
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.handleLog()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isLogin ? _vm.$t("logout") : _vm.$t("login")
                        ) +
                        " "
                    )
                  ]
                )
              ]),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLogin == false,
                      expression: "isLogin == false"
                    }
                  ]
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.move("join")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("join")))]
                  )
                ]
              ),
              _vm.isLogin
                ? _c("li", { staticClass: "notice" }, [
                    _c(
                      "a",
                      {
                        attrs: { alt: "알람" },
                        on: {
                          click: function($event) {
                            return _vm.move("alarm")
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "material-icons" }, [
                          _vm._v("notifications_none")
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.newMsg > 0,
                                expression: "newMsg > 0"
                              }
                            ],
                            staticClass: "newMsg"
                          },
                          [_vm._v(_vm._s(_vm.newMsg))]
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm.isLogin
                ? _c("li", { staticClass: "notice" }, [
                    _c(
                      "a",
                      {
                        attrs: { alt: "문의하기" },
                        on: {
                          click: function($event) {
                            return _vm.move("qna")
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "material-icons" }, [
                          _vm._v("support_agent")
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { alt: "언어" },
                    on: {
                      click: function($event) {
                        return _vm.langChange()
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.currentLang === "ko" ? "EN" : "KR"))
                    ])
                  ]
                )
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { attrs: { id: "nav_overlay" } })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/" } }, [
      _c("img", {
        staticClass: "logo",
        attrs: {
          src: require("@/assets/images/reviewmind/logo2.png"),
          alt: "logo"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/" } }, [
      _c("img", {
        staticClass: "logo",
        attrs: {
          src: require("@/assets/images/reviewmind/logo.png"),
          alt: "logo"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dashLogo" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          staticClass: "logo1",
          attrs: {
            src: require("@/assets/images/reviewmind/logo.png"),
            alt: "로고"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "watchDemo" }, [
      _c("a", { attrs: { href: "/WatchDemo" } }, [
        _vm._v(" Watch Demo "),
        _c("i", { staticClass: "fas fa-chevron-right" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }